<template>
	<div class="column-1">
		<div class="feedback__title">Обратная связь</div>
		<ul class="feedback__templates">
			<li
				v-for="theme in themes"
				:key="theme.id"
				class="feedback__templates__item"
			>
				<tk-link :to="`/profile/feedback/create/${theme.id}`">{{theme.title}}</tk-link>
			</li>
		</ul>
	</div>
	<div class="column-2">
		<ul v-if="considered.length" class="feedback__inprogress">
			<li class="feedback__inprogress__title">Рассматриваемые обращения</li>
			<li v-for="item in considered" :key="item.id" class="feedback__item">
				<router-link :to="`/profile/feedback/chat/${item.id}?theme_id=${item.theme.id}&status=${item.status}`">
					<div class="head">
						<div class="date">{{ getDate(item.last_reply.created_at) }}</div>
						<div
							:class="[
								'status',
								ticketKeys[item.status] ? ticketKeys[item.status].class : ''
							]"
						>
							{{
								ticketKeys[item.status] ? $t(ticketKeys[item.status].title) : ''
							}}
						</div>
					</div>
					<div class="title">{{ item.theme.title }}</div>
					<div class="description">{{ item.last_reply.text }}</div>
				</router-link>
			</li>
		</ul>
		<ul v-if="reviewed.length" class="feedback__completed">
			<li class="feedback__completed__title">Рассмотренные обращения</li>
			<li v-for="item in reviewed" :key="item.id" class="feedback__item">
				<router-link :to="`/profile/feedback/chat/${item.id}?theme_id=${item.theme.id}&status=${item.status}`">
					<div class="head">
						<div class="date">{{ getDate(item.last_reply.created_at) }}</div>
						<div
							:class="[
								'status',
								ticketKeys[item.status] ? ticketKeys[item.status].class : ''
							]"
						>
							{{
								ticketKeys[item.status] ? $t(ticketKeys[item.status].title) : ''
							}}
						</div>
					</div>
					<div class="title">{{ item.theme.title }}</div>
					<div class="description">{{ item.last_reply.text }}</div>
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
import { getDateInFormat } from '@/utils/dateTime'
import ticketKeys from '@/constants/tickets'
import { GET } from '@/constants/store/tickets/actions'
import { CONSIDERED, REVIEWED } from '@/constants/store/tickets/getters'

// Refactoring

export default {
	computed: {
		ticketKeys() {
			return ticketKeys
		},
		themes() {
			return this.$store.state.tickets.themes
		},
		considered() {
			return this.$store.getters[CONSIDERED]
		},
		reviewed() {
			return this.$store.getters[REVIEWED]
		}
	},
	methods: {
		getDate(payload) {
			return getDateInFormat(payload)
		}
	},
	async mounted() {
		await this.$store.dispatch(GET)
	}
}
</script>
